/* src/components/Industries.css */
.industry-container {
    padding: 20px;
    text-align: center;
}

.industry-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Change to row for horizontal layout */
    gap: 20px; /* Space between items */
}

.industry-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.industry-image {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    margin-bottom: 10px; /* Space between image and text */
}

.industry-details {
    font-size: 0.9em; /* Slightly smaller font size for details */
    color: #555; /* Gray color for better readability */
    margin-top: 10px; /* Space above details */
}

@media (min-width: 768px) {
    .industry-list {
        flex-direction: row; /* Switch to row layout on larger screens */
        justify-content: space-around;
    }
}
