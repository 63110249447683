#services {
    padding: 20px;
    background-color: #f9f9f9;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    width: 250px;
    text-align: center;
    transition: transform 0.2s;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
@media (min-width: 768px) {
    .industry-list {
        flex-direction: row; /* Switch to row layout on larger screens */
        justify-content: space-around;
    }
}